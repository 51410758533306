
.required {
  color: red !important;
}
.small {
  font-size: 1.2rem;
  margin-top: 0;
  padding-bottom: 2rem;
}
.error input, .error select, .error textarea, .error .control-indicator {
  border: 1px solid red;
}
form {
  z-index: 999 !important;
}
.optin-field > .control-label {
  margin-left: 2.8rem;
  margin-top: -0.2rem;
  margin-bottom: 0.2rem;
}
.optin-field > .control-label > p {
  font-size: 1.4rem;
}

.zipcode, .number {
    display: inline-block;
    width: 100px;
    margin-right: 10px;
}
.number {
    margin-right: 0px;
}
.residence, .street {
    display: inline-block;
    width: calc( 100% - 114px );
}
.street {
    margin-right: 10px;
}
.multiselect {
  position: relative;
}
.multiselect__current {
  line-height: 16px;
  min-height: 40px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: block;
  overflow: hidden;
  padding: 8px 12px 0;
  padding-right: 30px;
  white-space: nowrap;
  margin: 0;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid #E8E8E8;
  cursor: pointer;
}
.multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #E8E8E8;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 50;
  -webkit-overflow-scrolling: touch;
}
.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
}
.multiselect--above .multiselect__content-wrapper {
  top: 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: none;
  border-bottom: 1px solid #E8E8E8;
}
.multiselect__content::webkit-scrollbar {
  display: none;
}
.multiselect__element {
  display: block;
}
.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}
.multiselect__option:after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
}
.multiselect__option--highlight {
  background: #1e90ff;
  outline: none;
  color: white;
}
.multiselect__option--selected {
  background: #F3F3F3;
  color: #35495E;
  font-weight: bold;
}
.multiselect__option--selected.multiselect__option--highlight {
  background: #FF6A6A;
  color: #fff;
}
.multiselect--disabled {
  background: #ededed;
  pointer-events: none;
}
.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: #ededed;
  color: #a6a6a6;
}
.multiselect__option--disabled {
  background: #ededed;
  color: #a6a6a6;
  cursor: text;
  pointer-events: none;
}
.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede !important;
}
.multiselect-enter-active,
.multiselect-leave-active {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}
.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}
.multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}

.file {
  display: inline-block;
  margin-right: 10px;
  letter-spacing: .05rem;
  border-radius: 2px;
  padding: 5px;
  font-size: 1.6rem;
  color: #333;
  background: #eee;
  border: 1px solid #c7c7c7;
}
.file > .remove {
  font-weight: bold;
  color: red;
  cursor: pointer;
  margin-left: 10px;
}
.file > .remove:hover {
  color: #aa0000;
}
